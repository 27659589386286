<template>
<main><article class="cabecera-interior" style="background-image: url(/images/fondo-filosofia-exp-scaled.jpg);">
	<div class="cabecera-interior-caja">
		<div class="container">
			<div class="titulo-cabecera columns four">
								<h1>ASESORES DE INVERSIÓN</h1>
							</div>
			<div class="contenido-cabecera columns seven offset-by-one">
							</div>
		</div>
			<a href="#seccion-asesores" class="scroll"></a>
	</div>
</article>
<section id="seccion-asesores" class="seccion full">
	<div class="container-big">
		<div class="columns columns ten offset-by-one">
						<h2>Asesores</h2>
					</div>
				<div class="items flex flex-wrap columns ten offset-by-one items-10">
					<div class="item">
			<div class="numero columns one same" style="height: 187px;">1</div>
			<div class="caja-pilar columns ten same" style="height: 187px;">
				<h3>DANIELA INFANTE S.</h3>
				<div class="texto-83"><p><em>Experiencia Previa</em>:</p>
<ul>
<li>Concha y Toro</li>
<li>Tanner</li>
</ul>
<p><em>Educación</em>:</p>
<ul>
<li>Ingeniero Comercial de la Pontificia Universidad Católica de Chile</li>
</ul>
<p><em>Acreditación</em>:</p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 159px;">2</div>
			<div class="caja-pilar columns ten same" style="height: 159px;">
				<h3>EMILIANO VILLASECA G.</h3>
				<div class="texto-83"><p><em>Experiencia Previa</em>:</p>
<ul>
<li>Tanner</li>
</ul>
<p><em>Educación</em>:</p>
<ul>
<li>Ingeniero Comercial de la Pontificia Universidad Católica de Chile</li>
</ul>
<p><em>Acreditación</em>:</p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 216px;">3</div>
			<div class="caja-pilar columns ten same" style="height: 216px;">
				<h3>IGNACIO SILVA L.</h3>
				<div class="texto-83"><p><em>Experiencia Previa</em>:</p>
<ul>
<li>IM Trust (actualmente Credicorp Capital Chile)</li>
<li>Southern Cross Group</li>
</ul>
<p><em>Educación</em>:</p>
<ul>
<li>Ingeniero Comercial de la Pontificia Universidad Católica de Chile</li>
<li>MBA de The UCLA Anderson School of Business</li>
</ul>
<p><em>Acreditación</em>:</p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 159px;">4</div>
			<div class="caja-pilar columns ten same" style="height: 159px;">
				<h3>GONZALO ARAOS B. </h3>
				<div class="texto-83"><p><em>Experiencia Previa</em>:</p>
<ul>
<li>BICE Inversiones</li>
</ul>
<p><em>Educación</em>:</p>
<ul>
<li>Ingeniero Comercial de la Pontificia Universidad Católica de Chile</li>
</ul>
<p><em>Acreditación</em>:</p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 110px;">5</div>
			<div class="caja-pilar columns ten same" style="height: 110px;">
				<h3>MAXIMILIANO ABURTO S.</h3>
				<div class="texto-83"><p><em>Educación:</em></p>
<ul>
<li>Ingeniero Comercial de la Universidad de Chile con Mención en Economía</li>
</ul>
<p><em>Acreditación:</em></p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 159px;">6</div>
			<div class="caja-pilar columns ten same" style="height: 159px;">
				<h3>PABLO ACHONDO D.</h3>
				<div class="texto-83"><p><em>Experiencia Previa: </em></p>
<ul>
<li>AFP Provida</li>
</ul>
<p><em>Educación:</em></p>
<ul>
<li>Ingeniero Comercial de la Universidad Andrés Bello</li>
</ul>
<p><em>Acreditación:</em></p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 187px;">7</div>
			<div class="caja-pilar columns ten same" style="height: 187px;">
				<h3>ISABEL ALLENDES C.</h3>
				<div class="texto-83"><p><em>Experiencia Previa: </em></p>
<ul>
<li>Banco de Chile</li>
<li>Banco de Crédito e Inversiones</li>
</ul>
<p><em>Educación:</em></p>
<ul>
<li>Ingeniero Comercial de la Universidad Adolfo Ibáñez, máster en Marketing.</li>
</ul>
<p><em>Acreditación:</em></p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 159px;">8</div>
			<div class="caja-pilar columns ten same" style="height: 159px;">
				<h3>RODRIGO MONTES P.</h3>
				<div class="texto-83"><p><em>Experiencia Previa</em>:</p>
<ul>
<li>FYNSA</li>
</ul>
<p><em>Educación</em>:</p>
<ul>
<li>Ingeniero Comercial de la Pontificia Universidad Católica de Chile</li>
</ul>
<p><em>Acreditación</em>:</p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 110px;">9</div>
			<div class="caja-pilar columns ten same" style="height: 110px;">
				<h3>ROMINA GALVEZ Y.</h3>
				<div class="texto-83"><p><em>Educación:</em></p>
<ul>
<li>Ingeniero Comercial de la Universidad de los Andes</li>
</ul>
<p><em>Acreditación:</em></p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same" style="height: 110px;">10</div>
			<div class="caja-pilar columns ten same" style="height: 110px;">
				<h3>JULIO PESCE S.</h3>
				<div class="texto-83"><p><em>Educación:</em></p>
<ul>
<li>Ingeniero Comercial de la Universidad de Chile con Mención en Economía</li>
</ul>
<p><em>Acreditación:</em></p>
<p>Certificado de Acreditación de Conocimientos en el Mercado de Valores (CAMV)</p>
</div>
			</div>
		</div>
		</div>
			</div>
</section>
	</main>
  </template>
  
  <script>
  import jQuery from 'jquery'
  import { useHead } from '@vueuse/head';
  export default {
    name: 'AsesorPage',
    setup() {
    useHead({
      title: 'ASESORES DE INVERSIÓN | VICAPITAL',
      meta: [
        { name: 'description', content: '' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: 'ASESORES DE INVERSIÓN | VICAPITAL' },
        { property: 'og:description', content:  '' },
        { property: 'og:url', content: 'https://vicapital.cl/asesores' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/asesores' }]
    });
  },
	mounted() {
		this.initPage();
		this.setupWindowLoadEvent();
  },

  methods: {
    initPage() {
		const $j = jQuery.noConflict();
		//console.log("Iniciando mounted servicios")
		const id = (v) => document.getElementById(v);
		var ovrl = id("overlay");
		ovrl.style.display = "none";
    var logo_opacity = id("logo-header");
    logo_opacity.style.opacity = 1;

		$j('.scroll').on('click', function(event) {
			event.preventDefault();
			const target = this.hash;
			const $target = $j(target);

			$j('html, body').animate({
			scrollTop: $target.offset().top
			}, 2000, function() {
			});
		});
      // Equal height function
      

      // Window scroll and load functions
      const setupScrollEvents = () => {
          //console.log("Función principal de jQuery ejecutada");
          var height = $j(window).height(),
              header = $j("header");

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop();
            if (scroll >= height) {
              header.removeClass('sin-scroll').addClass("scroll");
            } else {
              header.removeClass("scroll").addClass('sin-scroll');
            }
          });

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop(),
                triggerBlur = $j(window).height() / 3;
            if (scroll >= triggerBlur) {
              $j('.full.blur').addClass("activo");
            } else {
              $j('.full.blur').removeClass("activo");
            }
          });
        };
        setupScrollEvents();

		
	
	$j(window).resize(function() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
          equalheight('.same');
          const height = $j(window).height();
          const width = $j(window).width();
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        });
    }
	,
	setupWindowLoadEvent() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
		const $j = jQuery.noConflict();
        equalheight('.same');
        const height = $j(window).height();
        const width = $j(window).width();
        if (width > 950){
          $j('.lamina').css('height',height);
        }

        const numeroMenu = (container) => {
          let contador = 0;
          $j(container).each(function() {
            contador++;
            const $el = $j(this);
            if (!$el.hasClass('con-numero')) {
              $el.prepend('<span>0'+contador+'.</span>');
              $el.addClass('con-numero');
            }
          });
        };

		

        numeroMenu('.menu-item');
        equalheight('.same');

        // Menu functions
        $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

        $j('.open-menu').click(function() {
          $j('header').toggleClass('open');
          $j('body').toggleClass('open-body');
          $j(this).toggleClass('open');
          return false;
        });
		/*
        $j('nav.principal .menu-item a').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              $j('header').toggleClass('open');
              $j('body').toggleClass('open-body');
              $j('.open-menu').toggleClass('open');
              return false;
            }
          }
        });
		*/

        $j('nav.info a').click(function() {
          const target = $j(this.hash);
          $j('.pop-left').removeClass('open');
          $j(target).toggleClass('open');
          return false;
        });

        $j('a.close-pop').click(function() {
          const target = $j(this.hash);
          $j(target).toggleClass('open');
          return false;
        });

        $j('.nav-responsive .nav-click').on('click', function() {
          if($j(this).parent().hasClass('activo')){
            $j('nav.nav .menu-item').removeClass('activo');
          } else {
            $j('nav.nav .menu-item').removeClass('activo');
            $j(this).parent().toggleClass('activo');
          }
        });
		/*
        $j('.scroll').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              return false;
            }
          }
        });
		*/
        
      }
  }
}
  </script>
  
  <style></style>
  