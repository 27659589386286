<template>
    <main><article class="cabecera-interior" style="background-image: url(/images/fondo-filosofia-exp-scaled.jpg);">
	<div class="cabecera-interior-caja">
		<div class="container">
			<div class="titulo-cabecera columns four">
								<h1>FILOSOFÍA <br />
DE INVERSIÓN</h1>
							</div>
			<div class="contenido-cabecera columns seven offset-by-one">
				<h2>LA CLAVE PARA LOGRAR LOS OBJETIVOS<br />
EN SU ESTRATEGIA DE INVERSIÓN</h2>
<p>Nuestra filosofía de inversión se basa en 4 pilares fundamentales que guían todas nuestras decisiones y recomendaciones: Visión de largo plazo, preservación de capital, disciplina y diversificación y no tener productos propios.<!--3839aa07--></p>
			</div>
		</div>
			<a href="#seccion-filosofia-de-inversion"  class="scroll"></a>
	</div>
</article>
<section id="seccion-filosofia-de-inversion" class="seccion full">
	<div class="container-big">
		<div class="columns columns ten offset-by-one">
						<h2>Nuestra<br />
Filosofía</h2>
					</div>
				<div class="items flex flex-wrap columns ten offset-by-one items-4">
					<div class="item">
			<div class="numero columns one same">01</div>
			<div class="caja-pilar columns ten same">
				<h3>VISIÓN DE<br />
LARGO PLAZO</h3>
				<div class="texto-83"><p>Creemos firmemente que la mejor forma de obtener resultados satisfactorios es manteniendo una visión de largo plazo, para lo cual estructuramos portafolios que puedan navegar por diferentes ciclos de mercado. Evitamos la especulación y apuestas de corto plazo.</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same">02</div>
			<div class="caja-pilar columns ten same">
				<h3>PRESERVACIÓN <br />
DE CAPITAL</h3>
				<div class="texto-83"><p>Ponemos especial énfasis en evitar alternativas de inversión que prometen altos retornos esperados con riesgos inciertos o desconocidos. Creemos que las inversiones deben ser simples y fáciles de entender.</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same">03</div>
			<div class="caja-pilar columns ten same">
			<h3>DISCIPLINA Y <br />
DIVERSIFICACIÓN</h3>
				<div class="texto-83"><p>Creemos que un proceso de inversión sistemático y disciplinado maximiza las probabilidades de obtener retornos atractivos en el largo plazo. La diversificación es una herramienta muy poderosa para alcanzar nuestros objetivos de inversión.</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same">04</div>
			<div class="caja-pilar columns ten same">
				<h3>No tenemos<br />
Productos propios</h3>
				<div class="texto-83"><p>Queremos mantener nuestra libertad en asesorar de manera independiente y sin conflictos de interés a nuestros clientes en las mejores alternativas disponibles en el mercado.</p>
</div>
			</div>
		</div>
		</div>
		</div>
</section>
	</main>
  </template>
  
  <script>
  import jQuery from 'jquery'
  import { useHead } from '@vueuse/head';
  export default {
    name: 'FilosofiaPage',
    setup() {
    useHead({
      title: 'Filosofía de inversión | VICAPITAL',
      meta: [
        { name: 'description', content: 'LA CLAVE PARA LOGRAR LOS OBJETIVOS EN SU ESTRATEGIA DE INVERSIÓN Nuestra filosofía de inversión se basa en 4 pilares fundamentales que guían todas nuestras decisiones y recomendaciones: Visión de largo plazo, preservación de capital, disciplina y diversificación y no tener productos propios.' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: 'Filosofía de inversión | VICAPITAL' },
        { property: 'og:description', content:  'LA CLAVE PARA LOGRAR LOS OBJETIVOS EN SU ESTRATEGIA DE INVERSIÓN Nuestra filosofía de inversión se basa en 4 pilares fundamentales que guían todas nuestras decisiones y recomendaciones: Visión de largo plazo, preservación de capital, disciplina y diversificación y no tener productos propios.' },
        { property: 'og:url', content: 'https://vicapital.cl/filosofia-de-inversion' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/filosofia-de-inversion' }]
    });
  },
	mounted() {
		this.initPage();
		this.setupWindowLoadEvent();
	
  },
  methods: {
    initPage() {
		const $j = jQuery.noConflict();
		//console.log("Iniciando mounted filosofia")
		const id = (v) => document.getElementById(v);
		var ovrl = id("overlay");
		ovrl.style.display = "none";

    var logo_opacity = id("logo-header");
    logo_opacity.style.opacity = 1;

		$j('.scroll').on('click', function(event) {
			event.preventDefault();
			const target = this.hash;
			const $target = $j(target);

			$j('html, body').animate({
			scrollTop: $target.offset().top
			}, 2000, function() {
			
			});
		});
      // Equal height function
      

      // Window scroll and load functions
      const setupScrollEvents = () => {
          //console.log("Función principal de jQuery ejecutada");
          var height = $j(window).height(),
              header = $j("header");

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop();
            if (scroll >= height) {
              header.removeClass('sin-scroll').addClass("scroll");
            } else {
              header.removeClass("scroll").addClass('sin-scroll');
            }
          });

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop(),
                triggerBlur = $j(window).height() / 3;
            if (scroll >= triggerBlur) {
              $j('.full.blur').addClass("activo");
            } else {
              $j('.full.blur').removeClass("activo");
            }
          });
        };
        setupScrollEvents();

		
	
	$j(window).resize(function() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
          equalheight('.same');
          const height = $j(window).height();
          const width = $j(window).width();
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        });
    }
	,
	setupWindowLoadEvent() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
		const $j = jQuery.noConflict();
        equalheight('.same');
        const height = $j(window).height();
        const width = $j(window).width();
        if (width > 950){
          $j('.lamina').css('height',height);
        }

        const numeroMenu = (container) => {
          let contador = 0;
          $j(container).each(function() {
            contador++;
            const $el = $j(this);
            if (!$el.hasClass('con-numero')) {
              $el.prepend('<span>0'+contador+'.</span>');
              $el.addClass('con-numero');
            }
          });
        };

		

        numeroMenu('.menu-item');
        equalheight('.same');

        // Menu functions
        $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

        $j('.open-menu').click(function() {
          $j('header').toggleClass('open');
          $j('body').toggleClass('open-body');
          $j(this).toggleClass('open');
          return false;
        });
		/*
        $j('nav.principal .menu-item a').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              $j('header').toggleClass('open');
              $j('body').toggleClass('open-body');
              $j('.open-menu').toggleClass('open');
              return false;
            }
          }
        });
		*/

        $j('nav.info a').click(function() {
          const target = $j(this.hash);
          $j('.pop-left').removeClass('open');
          $j(target).toggleClass('open');
          return false;
        });

        $j('a.close-pop').click(function() {
          const target = $j(this.hash);
          $j(target).toggleClass('open');
          return false;
        });

        $j('.nav-responsive .nav-click').on('click', function() {
          if($j(this).parent().hasClass('activo')){
            $j('nav.nav .menu-item').removeClass('activo');
          } else {
            $j('nav.nav .menu-item').removeClass('activo');
            $j(this).parent().toggleClass('activo');
          }
        });
		/*
        $j('.scroll').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              return false;
            }
          }
        });
		*/
        
      }
	
  }
}
  </script>
  
  <style></style>
  