<!-- src/components/FooterGlobal.vue -->
<template>
<footer>
    <div class="container-big">
        <div class="left columns three logo-footer-wrap"><a href="/" class="logo-header"><img src="/images/logo-vicapital.svg" alt="Vicapital" /></a></div>
                                        
        <div class="right columns three offset-by-five">
            <div class="datos-contacto">
                <h3><a href="/contacto">CONTACTO</a></h3>
                <a href="tel:+562 2233 6060">Tel +562 2233 6060</a>
                <a href="mailto:contacto@vicapital.cl">contacto@vicapital.cl</a>
                <p>Apoquindo 3000, Oficina 1501, Las Condes, Santiago</p>
            </div>
                <a href="/documentos/Codigo-de-Conducta-Vicapital-SpA.pdf" target="_blank" class="download-codigo mobile">Código de Conducta</a>
        </div>
            <div class="right columns one codigo-conducta desk">
                <a href="/documentos/Codigo-de-Conducta-Vicapital-SpA.pdf" target="_blank" class="download-codigo">Código de Conducta</a>
            </div>
    </div>
</footer>
</template>

<script>
export default {
  name: 'FooterGlobal'
}
</script>

<style scoped></style>
