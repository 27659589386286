import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createHead } from '@vueuse/head';


import HeaderGlobal from './components/HeaderGlobal.vue'
import FooterGlobal from './components/FooterGlobal.vue'
import MainGlobal from './pages/MainGlobal.vue'
import MainEng from './pages/en/MainEng.vue'
import ServiciosPage from './pages/ServiciosPage.vue'
import FilosofiaPage from './pages/FilosofiaPage.vue'
import EquipoPage from './pages/EquipoPage.vue'
import ContactoPage from './pages/ContactoPage.vue'
import ServiciosEng from './pages/en/ServiciosEng.vue'
import FilosofiaEng from './pages/en/FilosofiaEng.vue'
import EquipoEng from './pages/en/EquipoEng.vue'
import ContactoEng from './pages/en/ContactoEng.vue'
import AsesorPage from './pages/AsesorPage.vue'
import PersonaDetalle from './components/PersonaDetalle.vue'
import PersonaDetalleEng from './components/PersonaDetalleEng.vue'

const routes = [
  { path: '/', component: MainGlobal },
  { path: '/asesoria-en-inversiones', component: ServiciosPage },
  { path: '/filosofia-de-inversion', component: FilosofiaPage },
  { path: '/equipo', component: EquipoPage },
{ path: '/contacto', component: ContactoPage },
{ path: '/en', component: MainEng },
{ path: '/en/que-hacemos', component: ServiciosEng },
{ path: '/en/nuestra-filosofia', component: FilosofiaEng },
{ path: '/en/equipo', component: EquipoEng },
{ path: '/en/contacto', component: ContactoEng },
{ path: '/:catchAll(.*)', redirect: '/' },
{ path: '/asesores', component: AsesorPage },
{ path: '/equipo/:id' , name: 'PersonaDetalle',component: PersonaDetalle, props: true},
{ path: '/en/equipo/:id' , name: 'PersonaDetalleEng',component: PersonaDetalleEng, props: true},
  // Agrega las demás rutas aquí
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

const app = createApp(App)
const head = createHead();
app.use(router)
app.use(head)
app.component('HeaderGlobal', HeaderGlobal)
app.component('FooterGlobal', FooterGlobal)
app.mount('#app')
