<template>
    <main><article class="cabecera-interior" style="background-image: url(/images/fondo-que-hacemos-exp-scaled.jpg);">
	<div class="cabecera-interior-caja">
		<div class="container">
			<div class="titulo-cabecera columns four">
								<h1>QUÉ<br />
HACEMOS</h1>
							</div>
			<div class="contenido-cabecera columns seven offset-by-one">
				<h2>NOS DEDICAMOS AL CUIDADO DEL<br />
PATRIMONIO DE NUESTROS CLIENTES</h2>
<p>Entregamos una solución personalizada y a la medida de cada cliente, ofreciendo un servicio de asesoría patrimonial integral.<!--44879116--></p>
			</div>
		</div>
			<a href="#seccion-asesoria-en-inversiones" class="scroll"></a>
	</div>
</article>
<section id="seccion-asesoria-en-inversiones" class="seccion full">
	<div class="container-big">
		<div class="columns columns ten offset-by-one">
						<h2>¿QUÉ HACEMOS <br />
en VICAPITAl?</h2>
					</div>
          <div class="items flex flex-wrap columns eight offset-by-two items-6">
            <div class="item">
              <div class="numero columns one same">01</div>
              <div class="caja-pilar columns ten same">
                <h3>Asesoría en la<br />
determinación<br />
de objetivos</h3>
<div class="texto-83"><p>Asesoramos al inversionista<br />
en determinar sus objetivos<br />
de inversión en base a sus características y necesidades particulares. Nos preocupamos especialmente de conocer a cada cliente.</p>
</div>
</div>
</div>
<div class="item">
<div class="numero columns one same">02</div>
<div class="caja-pilar columns ten same">
  <h3>Diseño de <br />
la Estrategia</h3>
<div class="texto-83"><p>Proponemos y diseñamos una estrategia de inversión específica en base a los objetivos planteados, considerando todas las alternativas disponibles<br />
en el mercado.</p>
</div>
</div>
</div>
<div class="item">
<div class="numero columns one same">03</div>
<div class="caja-pilar columns ten same">
<h3>Optimización<br />
del portafolio</h3>
<div class="texto-83"><p>Entregamos asesoría en la selección, negociación y optimización de costos con administradores y custodios<br />
del patrimonio.</p>
</div>
</div>
</div>
<div class="item">
<div class="numero columns one same">04</div>
<div class="caja-pilar columns ten same">
<h3>Implementación<br />
del plan</h3>
				<div class="texto-83"><p>Llevamos a cabo la implementación de la estrategia a través de un proceso disciplinado, transparente y con especial cuidado en su ejecución.</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same">05</div>
			<div class="caja-pilar columns ten same">
				<h3>Análisis, seguimiento<br />
y supervisión</h3>
				<div class="texto-83"><p>Analizamos la coyuntura económica y los mercados, manteniendo un continuo seguimiento y supervisión de las contrapartes (administradoras de fondos, corredoras de bolsa y custodios).</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same">06</div>
			<div class="caja-pilar columns ten same">
				<h3>Consolidación<br />
y control de gestión</h3>
				<div class="texto-83"><p>Elaboramos informes de consolidación del patrimonio total, incluyendo análisis de rentabilidad, clases de activos, monedas, custodios y administradores.</p>
</div>
			</div>
		</div>
		</div>
			</div>
</section>
	</main>
  </template>
  
  <script>
  import jQuery from 'jquery';
  import { useHead } from '@vueuse/head';
  export default {
    name: 'ServiciosPage',
    
    setup() {
    useHead({
      title: 'Asesoría en inversiones | Vicapital',
      meta: [
        { name: 'description', content: 'Asesoría en inversiones, definimos su portafolios de inversión local e internacional, nos adaptándonos día a día a los cambios de los mercados' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: 'Asesoría en inversiones | Vicapital' },
        { property: 'og:description', content:  'Asesoría en inversiones, definimos su portafolios de inversión local e internacional, nos adaptándonos día a día a los cambios de los mercados' },
        { property: 'og:url', content: 'https://vicapital.cl/asesoria-en-inversiones' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/asesoria-en-inversiones' }]
    });
  },
	mounted() {
		this.initPage();
		this.setupWindowLoadEvent();
  },

  methods: {
    initPage() {
		const $j = jQuery.noConflict();
		//console.log("Iniciando mounted servicios")
		const id = (v) => document.getElementById(v);
		var ovrl = id("overlay");
		ovrl.style.display = "none";
    
    var logo_opacity = id("logo-header");
    logo_opacity.style.opacity = 1;

		$j('.scroll').on('click', function(event) {
			event.preventDefault();
			const target = this.hash;
			const $target = $j(target);

			$j('html, body').animate({
			scrollTop: $target.offset().top
			}, 2000, function() {
			});
		});
      // Equal height function
      

      // Window scroll and load functions
      const setupScrollEvents = () => {
          //console.log("Función principal de jQuery ejecutada");
          var height = $j(window).height(),
              header = $j("header");

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop();
            if (scroll >= height) {
              header.removeClass('sin-scroll').addClass("scroll");
            } else {
              header.removeClass("scroll").addClass('sin-scroll');
            }
          });

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop(),
                triggerBlur = $j(window).height() / 3;
            if (scroll >= triggerBlur) {
              $j('.full.blur').addClass("activo");
            } else {
              $j('.full.blur').removeClass("activo");
            }
          });
        };
        setupScrollEvents();

		
	
	$j(window).resize(function() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
          equalheight('.same');
          const height = $j(window).height();
          const width = $j(window).width();
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        });
    }
	,
	setupWindowLoadEvent() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
		const $j = jQuery.noConflict();
        equalheight('.same');
        const height = $j(window).height();
        const width = $j(window).width();
        if (width > 950){
          $j('.lamina').css('height',height);
        }

        const numeroMenu = (container) => {
          let contador = 0;
          $j(container).each(function() {
            contador++;
            const $el = $j(this);
            if (!$el.hasClass('con-numero')) {
              $el.prepend('<span>0'+contador+'.</span>');
              $el.addClass('con-numero');
            }
          });
        };

		

        numeroMenu('.menu-item');
        equalheight('.same');

        // Menu functions
        $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

        $j('.open-menu').click(function() {
          $j('header').toggleClass('open');
          $j('body').toggleClass('open-body');
          $j(this).toggleClass('open');
          return false;
        });
		/*
        $j('nav.principal .menu-item a').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              $j('header').toggleClass('open');
              $j('body').toggleClass('open-body');
              $j('.open-menu').toggleClass('open');
              return false;
            }
          }
        });
		*/

        $j('nav.info a').click(function() {
          const target = $j(this.hash);
          $j('.pop-left').removeClass('open');
          $j(target).toggleClass('open');
          return false;
        });

        $j('a.close-pop').click(function() {
          const target = $j(this.hash);
          $j(target).toggleClass('open');
          return false;
        });

        $j('.nav-responsive .nav-click').on('click', function() {
          if($j(this).parent().hasClass('activo')){
            $j('nav.nav .menu-item').removeClass('activo');
          } else {
            $j('nav.nav .menu-item').removeClass('activo');
            $j(this).parent().toggleClass('activo');
          }
        });
		/*
        $j('.scroll').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              return false;
            }
          }
        });
		*/
        
      }
  }
}
  </script>
  
  <style></style>
  