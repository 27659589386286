<template>
    <div :key="componentKey">
    <component :is="headerComponent"></component>
      <main><router-view :key="$route.fullPath"></router-view></main>
      <component :is="footerComponent"></component>
  </div>
 
</template>

<script>
import HeaderGlobal from './components/HeaderGlobal.vue'
import FooterGlobal from './components/FooterGlobal.vue'

import HeaderEng from './components/HeaderEng.vue'
import FooterEng from './components/FooterEng.vue'


export default {
  name: 'App',
  computed: {
    headerComponent() {
      return this.$route.path.startsWith('/en') ? 'HeaderEng' : 'HeaderGlobal';
    },
    footerComponent() {
      return this.$route.path.startsWith('/en') ? 'FooterEng' : 'FooterGlobal';
    }
  },
  components: {
    HeaderGlobal,
    FooterGlobal,
    HeaderEng,
    FooterEng
  },
  data() {
    return {
      componentKey: 0
    };
  },
  watch: {
    $route() {
      this.componentKey += 1;
    }
  },
}


</script>

<style>@import './assets/style.css';</style>
