<template>
    <main>
      <section id="contacto" class="contacto-fondo" style="background-image: url(/images/fondo-contacto-exp-scaled.jpg);">
        <div class="container">
          <div class="titulo">
            <h2>Contacto</h2>
            <p><!--2c2a42f2--></p>
          </div>
          <div class="row flex flex-bottom">
            <div class="columns five desk">
              <div class="datos-contacto">
                <a href="mailto:contacto@vicapital.cl" class="ico-mail-contacto">contacto@vicapital.cl</a>
                <a href="tel:+562 2233 6060" class="ico-fono-contacto">+562 2233 6060</a>
                <p class="ico-direccion-contacto">Apoquindo 3000, Oficina 1501, Las Condes, Santiago.</p>
              </div>
            </div>
            <div class="columns six offset-by-one">
              <form @submit.prevent="submitForm">
                <p>
                  <label>
                    <input v-model="formData.Nombre" size="40" maxlength="400" class="wpcf7-form-control wpcf7-text" autocomplete="name" aria-required="true" placeholder="Nombre" type="text" name="your-name" />
                    <span v-if="errors.name" class="error">{{ errors.name }}</span>
                  </label>
                </p>
                <p>
                  <label>
                    <input v-model="formData.Mail" size="40" maxlength="400" class="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email" autocomplete="email" aria-required="true" placeholder="Email" type="email" name="your-email" />
                    <span v-if="errors.email" class="error">{{ errors.email }}</span>
                  </label>
                </p>
                <p>
                  <label>Mensaje<br />
                    <textarea v-model="formData.Mensaje" cols="40" rows="10" maxlength="2000" class="wpcf7-form-control wpcf7-textarea" name="your-message"></textarea>
                    <span v-if="errors.message" class="error">{{ errors.message }}</span>
                  </label>
                </p>
                <p>
                  <input class="wpcf7-form-control wpcf7-submit has-spinner" type="submit" value="Enviar" />
                </p>
                <div class="wpcf7-response-output" v-if="successMessage" aria-hidden="false">{{ successMessage }}</div>
                <div class="wpcf7-response-output error" v-if="errorMessage" aria-hidden="false">{{ errorMessage }}</div>
              </form>
            </div>
            <div class="columns five mobile">
              <div class="datos-contacto">
                <a href="mailto:contacto@vicapital.cl" class="ico-mail-contacto">contacto@vicapital.cl</a>
                <a href="tel:+562 2233 6060" class="ico-fono-contacto">+562 2233 6060</a>
                <p class="ico-direccion-contacto">Apoquindo 3000, Oficina 1501, Las Condes, Santiago.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </template>
  
  <script>
   import jQuery from 'jquery';
   import { useHead } from '@vueuse/head';
  export default {
    name: 'ContactoPage',
    setup() {
    useHead({
      title: 'Contacto | VICAPITAL',
      meta: [
        { name: 'description', content: '' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: 'Contacto | VICAPITAL' },
        { property: 'og:description', content:  '' },
        { property: 'og:url', content: 'https://vicapital.cl/contacto' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/contacto' }]
    });
  },
    data() {
    return {
      formData: {
        Nombre: '',
        Mail: '',
        Mensaje: ''
      },
      successMessage: '',
      errorMessage: '',
      errors: {}
    };
  },
	mounted() {
		this.initPage();
		this.setupWindowLoadEvent();
},

methods: {
    validateForm() {
      this.errors = {};
      if (!this.formData.Nombre) {
        this.errors.name = 'El nombre es obligatorio.';
      }
      if (!this.formData.Mail) {
        this.errors.email = 'El email es obligatorio.';
      } else if (!this.validEmail(this.formData.Mail)) {
        this.errors.email = 'El email no es válido.';
      }
      if (!this.formData.Mensaje) {
        this.errors.message = 'El mensaje es obligatorio.';
      }
      return Object.keys(this.errors).length === 0;
    },
    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.errorMessage = 'Por favor, complete todos los campos obligatorios.';
        return;
      }
      const endpoint = 'https://prod2-05.brazilsouth.logic.azure.com:443/workflows/5bff246011f94f4fbfd30c240abf9ef2/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ZzsGo_ZA1MG27fMc0K5Zzd61dppj2WTH-cfsj6pGMfw';

      const formBody = {
        Nombre: this.formData.Nombre,
        Mail: this.formData.Mail,
        Mensaje: this.formData.Mensaje
      };

      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formBody)
        });

        const statusCode = response.status;

        if (statusCode === 202) {
          this.successMessage = 'Su mensaje ha sido enviado con éxito.';
          this.errorMessage = '';
          //console.log('Formulario enviado', responseData);
          //console.log('Código de estado:', statusCode);
          // Reset form fields
          this.formData.Nombre = '';
          this.formData.Mail = '';
          this.formData.Mensaje = '';
        } else {
          this.errorMessage = 'Hubo un error al enviar el formulario. Por favor, inténtelo de nuevo.';
          this.successMessage = '';
          //console.error('Error al enviar el formulario', responseData);
          //console.log('Código de estado:', statusCode);
        }
      } catch (error) {
        this.errorMessage = 'Hubo un error al enviar el formulario. Por favor, inténtelo de nuevo.';
        this.successMessage = '';
        //console.error('Error al enviar el formulario', error);
      }
    },
initPage() {
	const $j = jQuery.noConflict();
	//console.log("Iniciando mounted servicios")
	const id = (v) => document.getElementById(v);
	var ovrl = id("overlay");
	ovrl.style.display = "none";

	var logo_opacity = id("logo-header");
    logo_opacity.style.opacity = 1;

	$j('.scroll').on('click', function(event) {
		event.preventDefault();
		const target = this.hash;
		const $target = $j(target);

		$j('html, body').animate({
		scrollTop: $target.offset().top
		}, 2000, function() {
		});
	});
  // Equal height function
  

  // Window scroll and load functions
  const setupScrollEvents = () => {
	//console.log("Función principal de jQuery ejecutada");
	var height = $j(window).height(),
		header = $j("header");

	$j(window).scroll(() => {
		var scroll = $j(window).scrollTop();
		if (scroll >= height) {
			header.removeClass('sin-scroll').addClass("scroll");
		} else {
			header.removeClass("scroll").addClass('sin-scroll');
		}
	});

	$j(window).scroll(() => {
		var scroll = $j(window).scrollTop(),
			triggerBlur = $j(window).height() / 3;
		if (scroll >= triggerBlur) {
			$j('.full.blur').addClass("activo");
		} else {
			$j('.full.blur').removeClass("activo");
		}
	});
	};
	setupScrollEvents();

	

$j(window).resize(function() {
	const equalheight = (container) => {
	let currentTallest = 0,
		currentRowStart = 0,
		rowDivs = [],
		$el,
		topPosition = 0;

	$j(container).each(function() {
		$el = $j(this);
		$j($el).height('auto');
		topPosition = $el.position().top;

	if (currentRowStart != topPosition) {
		for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
			rowDivs[currentDiv].height(currentTallest);
		}
		rowDivs.length = 0; // empty the array
		currentRowStart = topPosition;
		currentTallest = $el.height();
		rowDivs.push($el);
	} else {
		rowDivs.push($el);
		currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	}
	for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
		rowDivs[currentDiv].height(currentTallest);
	}
	});
  };
	equalheight('.same');
	const height = $j(window).height();
	const width = $j(window).width();
	if (width > 950){
		$j('.lamina').css('height',height);
	}
	});
}
,
setupWindowLoadEvent() {
	const equalheight = (container) => {
	let currentTallest = 0,
		currentRowStart = 0,
		rowDivs = [],
		$el,
		topPosition = 0;

	$j(container).each(function() {
	$el = $j(this);
	$j($el).height('auto');
	topPosition = $el.position().top;

	if (currentRowStart != topPosition) {
		for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
			rowDivs[currentDiv].height(currentTallest);
		}
		rowDivs.length = 0; // empty the array
		currentRowStart = topPosition;
		currentTallest = $el.height();
		rowDivs.push($el);
	} else {
		rowDivs.push($el);
		currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	}
	for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
		rowDivs[currentDiv].height(currentTallest);
	}
	});
  };
	const $j = jQuery.noConflict();
	equalheight('.same');
	const height = $j(window).height();
	const width = $j(window).width();
	if (width > 950){
		$j('.lamina').css('height',height);
	}

	const numeroMenu = (container) => {
	let contador = 0;
	$j(container).each(function() {
		contador++;
		const $el = $j(this);
		if (!$el.hasClass('con-numero')) {
			$el.prepend('<span>0'+contador+'.</span>');
			$el.addClass('con-numero');
		}
	});
	};

	

	numeroMenu('.menu-item');
	equalheight('.same');

	// Menu functions
	$j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

	$j('.open-menu').click(function() {
		$j('header').toggleClass('open');
		$j('body').toggleClass('open-body');
		$j(this).toggleClass('open');
		return false;
	});
	/*
	$j('nav.principal .menu-item a').click(function() {
	  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
		let target = $j(this.hash);
		target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
		if (target.length) {
		  $j('html,body').animate({
			scrollTop: target.offset().top
		  }, 2000);
		  $j('header').toggleClass('open');
		  $j('body').toggleClass('open-body');
		  $j('.open-menu').toggleClass('open');
		  return false;
		}
	  }
	});
	*/

	$j('nav.info a').click(function() {
		const target = $j(this.hash);
		$j('.pop-left').removeClass('open');
		$j(target).toggleClass('open');
		return false;
	});

	$j('a.close-pop').click(function() {
		const target = $j(this.hash);
		$j(target).toggleClass('open');
		return false;
	});

	$j('.nav-responsive .nav-click').on('click', function() {
		if($j(this).parent().hasClass('activo')){
			$j('nav.nav .menu-item').removeClass('activo');
		} else {
		$j('nav.nav .menu-item').removeClass('activo');
		$j(this).parent().toggleClass('activo');
	}
	});
	/*
	$j('.scroll').click(function() {
	  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
		let target = $j(this.hash);
		target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
		if (target.length) {
		  $j('html,body').animate({
			scrollTop: target.offset().top
		  }, 2000);
		  return false;
		}
	  }
	});
	*/
	
  }
}
}
</script>

<style></style>