<!-- src/components/HeaderEng.vue -->
<template>
  <div id="overlay">
  <div class="logo">
  <span id="loader-logo-hover"><span></span></span>
  </div>   
</div>   
<header class="sin-scroll">
  <a href="/en" class="logo-header" id="logo-header">
      <img src="/images/logo-vicapital.svg" alt="Vicapital" />
  </a>
  <div class="right">
      <div class="wpml-ls-statics-shortcode_actions wpml-ls wpml-ls-legacy-list-horizontal">
<ul><li class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-en wpml-ls-first-item wpml-ls-item-legacy-list-horizontal">
      <a href="/en" class="wpml-ls-link">
                  <span class="wpml-ls-display">Eng</span></a>
    </li><li class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-es wpml-ls-current-language wpml-ls-last-item wpml-ls-item-legacy-list-horizontal">
      <a href="/" class="wpml-ls-link">
                  <span class="wpml-ls-native">Esp</span></a>
    </li></ul>
</div>
      <a href="#menu" class="open-menu">Open Menu</a>
  </div>
  <menu id="menu">
      <nav class="principal">
          <ul id="menu-menu-1-esp" class="nav-menu-principal"><li id="menu-item-400" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-43 current_page_item menu-item-400"><router-link to="/en" @click="handleClick">Home</router-link></li>
<li id="menu-item-405" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-405"><a href="#section-1"  class="scroll2" @click.prevent="handleQuienesSomosClick">About Us</a></li>
<li id="menu-item-403" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-403"><router-link to="/en/que-hacemos" @click="handleClick">Services</router-link></li>
<li id="menu-item-402" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-402"><router-link to="/en/nuestra-filosofia" @click="handleClick">Investment Philosophy</router-link></li>
<li id="menu-item-404" class="menu-item menu-item-type-post_type_archive menu-item-object-equipo menu-item-404"><router-link to="/en/equipo" @click="handleClick">Team</router-link></li>
<li id="menu-item-401" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-401"><router-link to="/en/contacto" @click="handleClick">Contact</router-link></li>
</ul>			</nav>
      <a href="https://clientes.vicapital.cl" class="acceso-clientes">Clients Access</a>
  </menu>
</header>

</template>
  
  <script>
  import jQuery from 'jquery'
export default {
  name: 'HeaderEng',
  mounted() {
		this.initPage();
  },
  methods: {
    initPage() {
      const $j = jQuery.noConflict();
      $j('.scroll2').on('click', function (event) {
        event.preventDefault();
        const target = this.hash;
        const $target = $j(target);
        //console.log(target)
        $j('html, body').animate({
          scrollTop: $target.offset().top
        }, 2000, function () {
        });
        document.querySelector('header').classList.toggle('open');
        document.querySelector('body').classList.toggle('open-body');
        document.querySelector('.open-menu').classList.toggle('open');
      });
    },
    handleClick() {
      this.toggleMenu();
    },
    handleQuienesSomosClick(event) {
      event.preventDefault();
      if (this.$route.path === '/en') {
        const targetElement = document.querySelector('#section-1');
        if (targetElement) {
          const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({
            top: elementPosition,
            behavior: 'smooth'
          });
        }
        
        
      } else {
        this.$router.push('/en').then(() => {
          this.$nextTick(() => {
            const targetElement = document.querySelector('#section-1');
            if (targetElement) {
              const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
              window.scrollTo({
                top: elementPosition,
                behavior: 'smooth'
              });
            }
            document.querySelector('header').classList.toggle('open');
          //document.querySelector('body').classList.toggle('open-body');
          document.querySelector('.open-menu').classList.toggle('open');
          });
        });
      }
    },

    toggleMenu() {
      document.querySelector('header').classList.toggle('open');
      document.querySelector('body').classList.toggle('open-body');
      document.querySelector('.open-menu').classList.toggle('open');
      //console.log('Menu toggled');
    }
  }
}
</script>
  
  <style scoped></style>