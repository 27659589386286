<template>

<div v-if="loading" >
    <!-- Aquí puedes insertar una animación o un ícono de carga -->
  </div>

  <main v-else-if="persona" class="equipo-template-default single single-equipo postid-90">
    <article :id="persona.id">
      <router-link to="/en/equipo" class="back"><img src="/images/icon-back.svg" /></router-link>
        
    
      <div class="next_post_link">
        <router-link :to="{ name: 'PersonaDetalleEng', params: { id: personaAnterior.id } }" v-if="personaAnterior">{{ personaAnterior.nombre }}</router-link>
      </div>
      <div class="container">
        <div class="columns ten offset-by-two">
          <div class="columns five same">
            <div class="thumbnail">
              <img :src="persona.imagen_grande" :alt="persona.nombre" />
            </div>
          </div>
          <div class="cont-equipo columns six same">
            <h2 v-html="persona.nombreHTML"></h2>
            <h3 class="cargo">{{ persona.cargo }}</h3>
            <div class="texto" v-html="persona.texto"></div>
            <div class="equipo-areas" v-if="persona.areas">
              <h3>Áreas de práctica</h3>
              <p>{{ persona.areas }}</p>
            </div>
            <div class="bajada">
              <a v-if="persona.email" :href="'mailto:' + persona.email" class="email">{{ persona.email }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="previous_post_link">
        <router-link :to="{ name: 'PersonaDetalleEng', params: { id: personaSiguiente.id } }" v-if="personaSiguiente">{{ personaSiguiente.nombre }}</router-link>
      </div>
    </article>
  </main>
  <div v-else>

  </div>
</template>

<script>

import jQuery	from 'jquery';
export default {
  name: 'PersonaDetalleEng',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      persona: null,
      personaAnterior: null,
      personaSiguiente: null,
      loading: true, // Estado de carga inicial
    };
  },


  methods: {
    fetchPersona() {
      const id = this.$route.params.id;
      
      const personas = this.$root.personas; // Asegúrate de tener una lista de personas en el estado raíz.
      if (!personas) {
        this.$router.push('/en/equipo');
        return;
      }
      if (personas) {
        const index = personas.findIndex(persona => persona.id === id);
        if (index !== -1) {
          this.persona = personas[index];
          this.personaAnterior = personas[index - 1] || null;
          this.personaSiguiente = personas[index + 1] || null;
          this.preloadImages([this.persona.imagen_grande]);
        } else {
          this.persona = null;
        }
      }
    },
    preloadImages(images) {
    let loadedImages = 0;
    const totalImages = images.length;

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === totalImages) {
          this.loading = false; // Cambia el estado de carga a `false` cuando todas las imágenes se hayan cargado
        }
      };
    });
  },

    initPage() {
  const $j = jQuery.noConflict();
  //console.log("Iniciando mounted servicios")
  const id = (v) => document.getElementById(v);
  var ovrl = id("overlay");
  ovrl.style.display = "none";

  $j('.scroll').on('click', function(event) {
  event.preventDefault();
  const target = this.hash;
  const $target = $j(target);

  $j('html, body').animate({
  scrollTop: $target.offset().top
  }, 2000, function() {
  });
  });
  // Equal height function
  

  // Window scroll and load functions
  const setupScrollEvents = () => {
  //console.log("Función principal de jQuery ejecutada");
  var height = $j(window).height(),
  header = $j("header");

  $j(window).scroll(() => {
  var scroll = $j(window).scrollTop();
  if (scroll >= height) {
    header.removeClass('sin-scroll').addClass("scroll");
  } else {
    header.removeClass("scroll").addClass('sin-scroll');
  }
  });

  $j(window).scroll(() => {
  var scroll = $j(window).scrollTop(),
    triggerBlur = $j(window).height() / 3;
  if (scroll >= triggerBlur) {
    $j('.full.blur').addClass("activo");
  } else {
    $j('.full.blur').removeClass("activo");
  }
  });
  };
  setupScrollEvents();

  

$j(window).resize(function() {
  const equalheight = (container) => {
  let currentTallest = 0,
  currentRowStart = 0,
  rowDivs = [],
  $el,
  topPosition = 0;

  $j(container).each(function() {
  $el = $j(this);
  $j($el).height('auto');
  topPosition = $el.position().top;

  if (currentRowStart != topPosition) {
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
    rowDivs[currentDiv].height(currentTallest);
  }
  rowDivs.length = 0; // empty the array
  currentRowStart = topPosition;
  currentTallest = $el.height();
  rowDivs.push($el);
  } else {
  rowDivs.push($el);
  currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  rowDivs[currentDiv].height(currentTallest);
  }
  });
  };
  equalheight('.same');
  const height = $j(window).height();
  const width = $j(window).width();
  if (width > 950){
  $j('.lamina').css('height',height);
  }
  });
}
,
setupWindowLoadEvent() {
  const equalheight = (container) => {
  let currentTallest = 0,
  currentRowStart = 0,
  rowDivs = [],
  $el,
  topPosition = 0;

  $j(container).each(function() {
  $el = $j(this);
  $j($el).height('auto');
  topPosition = $el.position().top;

  if (currentRowStart != topPosition) {
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
    rowDivs[currentDiv].height(currentTallest);
  }
  rowDivs.length = 0; // empty the array
  currentRowStart = topPosition;
  currentTallest = $el.height();
  rowDivs.push($el);
  } else {
  rowDivs.push($el);
  currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
  for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  rowDivs[currentDiv].height(currentTallest);
  }
  });
  };
  const $j = jQuery.noConflict();
  equalheight('.same');
  const height = $j(window).height();
  const width = $j(window).width();
  if (width > 950){
  $j('.lamina').css('height',height);
  }

  const numeroMenu = (container) => {
  let contador = 0;
  $j(container).each(function() {
  contador++;
  const $el = $j(this);
  if (!$el.hasClass('con-numero')) {
    $el.prepend('<span>0'+contador+'.</span>');
    $el.addClass('con-numero');
  }
  });
  };

  

  numeroMenu('.menu-item');
  equalheight('.same');

  // Menu functions
  $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

  $j('.open-menu').click(function() {
  $j('header').toggleClass('open');
  $j('body').toggleClass('open-body');
  $j(this).toggleClass('open');
  return false;
  });
  /*
  $j('nav.principal .menu-item a').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
  let target = $j(this.hash);
  target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
  if (target.length) {
    $j('html,body').animate({
    scrollTop: target.offset().top
    }, 2000);
    $j('header').toggleClass('open');
    $j('body').toggleClass('open-body');
    $j('.open-menu').toggleClass('open');
    return false;
  }
  }
  });
  */

  $j('nav.info a').click(function() {
  const target = $j(this.hash);
  $j('.pop-left').removeClass('open');
  $j(target).toggleClass('open');
  return false;
  });

  $j('a.close-pop').click(function() {
  const target = $j(this.hash);
  $j(target).toggleClass('open');
  return false;
  });

  $j('.nav-responsive .nav-click').on('click', function() {
  if($j(this).parent().hasClass('activo')){
    $j('nav.nav .menu-item').removeClass('activo');
  } else {
  $j('nav.nav .menu-item').removeClass('activo');
  $j(this).parent().toggleClass('activo');
  }
  });
  /*
  $j('.scroll').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
  let target = $j(this.hash);
  target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
  if (target.length) {
    $j('html,body').animate({
    scrollTop: target.offset().top
    }, 2000);
    return false;
  }
  }
  });
  */
  
  },


  },
  watch: {
    $route: 'fetchPersona',
  },
  mounted() {

    
    this.fetchPersona();
    this.initPage();
    this.setupWindowLoadEvent();
    document.body.classList.remove('home');
    document.body.classList.remove('page-template');
    document.body.classList.remove('page-template-page-home');
    document.body.classList.remove('page-template-page-home-php');
    document.body.classList.remove('page');
    document.body.classList.remove('page-id-43');
    document.body.classList.add('equipo-template-default');
    document.body.classList.add('single');
    document.body.classList.add('single-equipo');
    document.body.classList.add('postid-90');

 
  },

  beforeUnmount() {
    document.body.classList.add('home');
    document.body.classList.add('page-template');
    document.body.classList.add('page-template-page-home');
    document.body.classList.add('page-template-page-home-php');
    document.body.classList.add('page');
    document.body.classList.add('page-id-43');
    document.body.classList.remove('equipo-template-default');
    document.body.classList.remove('single');
    document.body.classList.remove('single-equipo');
    document.body.classList.remove('postid-90');
  }
};
</script>

<style scoped></style>
