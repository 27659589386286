<template>
    <main><article class="cabecera-interior" style="background-image: url(/images/fondo-que-hacemos-exp-scaled.jpg);">
	<div class="cabecera-interior-caja">
		<div class="container">
			<div class="titulo-cabecera columns four">
								<h1>WHAT<br />
WE DO?</h1>
							</div>
			<div class="contenido-cabecera columns seven offset-by-one">
				<h2>At Vicapital we are dedicated to the care of our clients’ wealth</h2>
<p>We provide a personalized and tailored solution to each client, offering a comprehensive wealth advisory service.<!--44879116--></p>
			</div>
		</div>
			<a href="#seccion-asesoria-en-inversiones" class="scroll"></a>
	</div>
</article>
<section id="seccion-asesoria-en-inversiones" class="seccion full">
	<div class="container-big">
		<div class="columns columns ten offset-by-one">
						<h2>WHAT <br />
WE DO?</h2>
					</div>
          <div class="items flex flex-wrap columns eight offset-by-two items-6">
            <div class="item">
              <div class="numero columns one same">01</div>
              <div class="caja-pilar columns ten same">
                <h3>Advisory in <br>
setting<br>
objectives</h3>
<div class="texto-83"><p>We advise investors in determining their investment objectives based on their specific characteristics and needs. We take special care in getting to know each client.</p>
</div>
</div>
</div>
<div class="item">
<div class="numero columns one same">02</div>
<div class="caja-pilar columns ten same">
  <h3>Strategy<br>
    Design</h3>
<div class="texto-83"><p>We propose and design a specific investment strategy based on the stated objectives, considering all available alternatives in the market.</p>
</div>
</div>
</div>
<div class="item">
<div class="numero columns one same">03</div>
<div class="caja-pilar columns ten same">
<h3>Portfolio
  optimization</h3>
<div class="texto-83"><p>We provide advice on the selection, negotiation, and optimization of costs with asset managers and custodians.</p>
</div>
</div>
</div>
<div class="item">
<div class="numero columns one same">04</div>
<div class="caja-pilar columns ten same">
<h3>Plan<br>
  implementation</h3>
				<div class="texto-83"><p>We carry out the implementation of the strategy through a disciplined, transparent process, taking special care in its execution.</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same">05</div>
			<div class="caja-pilar columns ten same">
				<h3>Analysis, monitoring,
          and supervision</h3>
				<div class="texto-83"><p>We analyze the economic situation and markets, maintaining continuous monitoring and supervision of our clients’ investments (fund managers, stockbrokers, and custodians).</p>
</div>
			</div>
		</div>
		<div class="item">
			<div class="numero columns one same">06</div>
			<div class="caja-pilar columns ten same">
				<h3>Consolidation
          <br>and management <br>control</h3>
				<div class="texto-83"><p>We prepare consolidated reports of the total assets, including profitability analysis, asset classes, currencies, custodians, and managers.</p>
</div>
			</div>
		</div>
		</div>
			</div>
</section>
	</main>
  </template>
  
  <script>
  import jQuery from 'jquery'
  import { useHead } from '@vueuse/head';
  export default {
    name: 'ServiciosPage',
    setup() {
    useHead({
      title: 'SERVICES | VICAPITAL',
      meta: [
        { name: 'description', content: 'At Vicapital we are dedicated to the care of our clients’ wealth We provide a personalized and tailored solution to each client, offering a comprehensive wealth advisory service.' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: 'SERVICES | VICAPITAL' },
        { property: 'og:description', content:  'At Vicapital we are dedicated to the care of our clients’ wealth We provide a personalized and tailored solution to each client, offering a comprehensive wealth advisory service.' },
        { property: 'og:url', content: 'https://vicapital.cl/en/que-hacemos' },
        { property: 'og:locale', content: 'en_US' },
      ],
      link:
      [{ rel: 'canonical', href: 'https://vicapital.cl/en/que-hacemos' }]
    });
  },
	mounted() {
		this.initPage();
		this.setupWindowLoadEvent();
  },

  methods: {
    initPage() {
		const $j = jQuery.noConflict();
		//console.log("Iniciando mounted servicios")
		const id = (v) => document.getElementById(v);
		var ovrl = id("overlay");
		ovrl.style.display = "none";

    var logo_opacity = id("logo-header");
    logo_opacity.style.opacity = 1;

		$j('.scroll').on('click', function(event) {
			event.preventDefault();
			const target = this.hash;
			const $target = $j(target);

			$j('html, body').animate({
			scrollTop: $target.offset().top
			}, 2000, function() {
			});
		});
      // Equal height function
      

      // Window scroll and load functions
      const setupScrollEvents = () => {
          //console.log("Función principal de jQuery ejecutada");
          var height = $j(window).height(),
              header = $j("header");

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop();
            if (scroll >= height) {
              header.removeClass('sin-scroll').addClass("scroll");
            } else {
              header.removeClass("scroll").addClass('sin-scroll');
            }
          });

          $j(window).scroll(() => {
            var scroll = $j(window).scrollTop(),
                triggerBlur = $j(window).height() / 3;
            if (scroll >= triggerBlur) {
              $j('.full.blur').addClass("activo");
            } else {
              $j('.full.blur').removeClass("activo");
            }
          });
        };
        setupScrollEvents();

		
	
	$j(window).resize(function() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
          equalheight('.same');
          const height = $j(window).height();
          const width = $j(window).width();
          if (width > 950){
            $j('.lamina').css('height',height);
          }
        });
    }
	,
	setupWindowLoadEvent() {
		const equalheight = (container) => {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

        $j(container).each(function() {
          $el = $j(this);
          $j($el).height('auto');
          topPosition = $el.position().top;

          if (currentRowStart != topPosition) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPosition;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
          for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
      };
		const $j = jQuery.noConflict();
        equalheight('.same');
        const height = $j(window).height();
        const width = $j(window).width();
        if (width > 950){
          $j('.lamina').css('height',height);
        }

        const numeroMenu = (container) => {
          let contador = 0;
          $j(container).each(function() {
            contador++;
            const $el = $j(this);
            if (!$el.hasClass('con-numero')) {
              $el.prepend('<span>0'+contador+'.</span>');
              $el.addClass('con-numero');
            }
          });
        };

		

        numeroMenu('.menu-item');
        equalheight('.same');

        // Menu functions
        $j('.nav .menu-item').has('ul').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');

        $j('.open-menu').click(function() {
          $j('header').toggleClass('open');
          $j('body').toggleClass('open-body');
          $j(this).toggleClass('open');
          return false;
        });
		/*
        $j('nav.principal .menu-item a').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              $j('header').toggleClass('open');
              $j('body').toggleClass('open-body');
              $j('.open-menu').toggleClass('open');
              return false;
            }
          }
        });
		*/

        $j('nav.info a').click(function() {
          const target = $j(this.hash);
          $j('.pop-left').removeClass('open');
          $j(target).toggleClass('open');
          return false;
        });

        $j('a.close-pop').click(function() {
          const target = $j(this.hash);
          $j(target).toggleClass('open');
          return false;
        });

        $j('.nav-responsive .nav-click').on('click', function() {
          if($j(this).parent().hasClass('activo')){
            $j('nav.nav .menu-item').removeClass('activo');
          } else {
            $j('nav.nav .menu-item').removeClass('activo');
            $j(this).parent().toggleClass('activo');
          }
        });
		/*
        $j('.scroll').click(function() {
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
            let target = $j(this.hash);
            target = target.length ? target : $j('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $j('html,body').animate({
                scrollTop: target.offset().top
              }, 2000);
              return false;
            }
          }
        });
		*/
        
      }
  }
}
  </script>
  
  <style></style>
  